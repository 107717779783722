var buttonCTA;
var buttonSubmit;
var form;
var formInput;
var mceSuccessResponse;
var signupFormVisible = false;
var alreadySubscribed = false;
var callToAction;
var wabaBodyPic;

function  ShowSignupForm(form, formInput, buttonCTA, buttonSubmit) {
	// show form
	formInput.style.display = "block";

	HideCallToAction();

	// show button Submit
	buttonSubmit.className = "mc-embedded-subscribe Button Submit";

	// set bool
	signupFormVisible = true;
}

function HideSignupForm(form, formInput, buttonCTA, buttonSubmit) {
	// hide form
	formInput.style.display = "none";

	ShowCallToAction();

	// hid submit button
	buttonSubmit.className = "mc-embedded-subscribe Button Submit Hide";

	// set bool
	signupFormVisible = false;
}

function ShowCallToAction() {
	callToAction.className = "GridItem";
	buttonCTA.className = "Button CTA";
}

function ShowCallToActionText()
{
	callToAction.className = "GridItem";
}

function HideCallToAction() {
	callToAction.className = "GridItem Hide";
	buttonCTA.className = "Button CTA Hide";
}

function AnimWabaWink()
{
	wabaBodyPic.src = "Gifs/WabaWink1.gif"
}

function AnimWabaHappy()
{
	wabaBodyPic.src = "Gifs/WabaHappy1.gif";
}

// this method keeps looking for the subscribe success method
// and hides the signup form and CTA button if it finds it
function CheckForSubscribeLoop() {
    setTimeout(function () {

    	if (!alreadySubscribed)
    	{
        	CheckForSubscribeLoop();
    	}

    	if (alreadySubscribed)
    	{
    		HideSignupForm(form, formInput, buttonCTA, buttonSubmit);
    		HideCallToAction();
    		ShowCallToActionText();
    		AnimWabaHappy();
    		callToAction.innerText = "you are now heartlinked to Waba";
    	}

    	if (mceSuccessResponse.innerText === "Thank you for subscribing!")
    	{
    		alreadySubscribed = true;
    	}

    }, 500);
}

document.addEventListener('DOMContentLoaded', function() {

	wabaBodyPic = document.getElementById("WabaBodyPic");
	buttonCTA = document.getElementById("SignupButtonCTA");
	buttonSubmit = document.getElementById("mc-embedded-subscribe");
	form = document.getElementById("mc-embedded-subscribe-form");
	formInput = document.getElementById("mc_embed_signup");
	mceSuccessResponse = document.getElementById("mce-success-response");
	callToAction = document.getElementById("CallToAction");

	HideSignupForm(form, formInput, buttonCTA, buttonSubmit);

	buttonCTA.onclick = function() {
		if (!signupFormVisible) {
			ShowSignupForm(form, formInput, buttonCTA, buttonSubmit);
			AnimWabaWink();
		}
	}

	CheckForSubscribeLoop();

});